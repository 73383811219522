<template>
  <div v-if="!!data" class="clipart">
    <b-img v-for="item in data" :key="item.id" class="img" :src="item.image" :style="imageStyles(item)"></b-img>
  </div>
</template>

<script>
export default {
  name: 'clipart',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    imageStyles(imageData) {
      const styles = {
        width: imageData.width !== null ? `${imageData.width}px` : undefined,
        [imageData.vertical.position]: `${imageData.vertical.offset}${imageData.vertical.unit}`,
        [imageData.horizontal.position]: `${imageData.horizontal.offset}px`,
      }
      switch (imageData.orientation) {
        case 'rotate':
          styles.transform = 'rotate(180deg)'
          break
        case 'flip':
          styles.transform = 'scaleX(-1)'
          break
      }
      return styles
    },
  },
}
</script>

<style lang="scss" scoped>
.clipart {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  .img {
    position: absolute;
  }
}
</style>

<template>
  <div class="donut-container">
    <div class="donut" :style="{ width: `${size + lineExtension * 2}px`, height: `${size / 2 + lineExtension}px` }">
      <div
        class="top-half"
        :style="{ width: `${size}px`, height: `${size / 2}px`, top: `${lineExtension}px`, left: `${lineExtension}px` }"
      >
        <div
          class="color-circle"
          :style="{
            width: `${size}px`,
            height: `${size}px`,
            background,
          }"
        >
          <div
            class="negative-color"
            :style="{ width: `${size}px`, height: `${size / 2}px`, transform: `rotate(${negativeRotation}deg)` }"
          ></div>
          <div
            class="middle-circle"
            :style="{
              width: `${size - thickness * 2}px`,
              height: `${size - thickness * 2}px`,
              left: `${thickness}px`,
              top: `${thickness}px`,
            }"
          ></div>
        </div>
      </div>
      <div
        v-if="displayedPercent"
        class="line-container"
        :style="{
          width: `${size + lineExtension * 2}px`,
          height: `${size / 2 + lineExtension}px`,
          transform: `rotate(${negativeRotation}deg)`,
        }"
      >
        <div class="line"></div>
      </div>
    </div>
    <capacity :percent="percent" class="mt-2" :hide-info="hideInfo" @info="$emit('info')" />
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'donut',
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    thickness: {
      type: Number,
      default: 12,
    },
    size: {
      type: Number,
      default: 200,
    },
    duration: {
      type: Number,
      default: 1200,
    },
    show: {
      type: Boolean,
      default: true,
    },
    hideInfo: {
      type: Boolean,
      default: false,
    },
    isPdf: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      displayedPercent: 0,
      startTimestamp: null,
      lineExtension: 10,
      gradientValues: {
        red: '5%',
        purple: '15%',
        blue: '40%',
        integralBlue: '55%',
        duration: 1,
        delay: 0.45,
        ease: 'power2.out',
      },
    }
  },
  computed: {
    negativeRotation() {
      return -360 + (this.displayedPercent / 100) * 180
    },
    background() {
      return !this.isPdf
        ? `
        conic-gradient(
          from -90deg,
          var(--red-light) ${this.gradientValues.red},
          var(--purple) ${this.gradientValues.purple},
          var(--blue) ${this.gradientValues.blue},
          var(--integral-blue) ${this.gradientValues.integralBlue}
        )
      `
        : null
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.init()
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.displayedPercent = !this.isPdf ? 0 : this.percent
      this.startTimestamp = null

      if (!this.isPdf) {
        gsap.to(this, { displayedPercent: this.percent, duration: 0.8, delay: 0.45, ease: 'back.out(1.5)' })
        gsap.fromTo(
          this.gradientValues,
          {
            red: '0%',
            purple: '50%',
            blue: '100%',
            integralBlue: '150%',
          },
          {
            red: '5%',
            purple: '15%',
            blue: '40%',
            integralBlue: '55%',
            duration: 1,
            delay: 0.45,
            ease: 'power2.out',
          }
        )
      }
    },
  },
}
</script>

<style scoped lang="scss">
.donut-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.donut {
  position: relative;

  .top-half {
    overflow: hidden;
    position: relative;
  }

  .color-circle {
    // background: conic-gradient(
    //   from -90deg,
    //   var(--red-light) 5%,
    //   var(--purple) 15%,
    //   var(--blue) 30%,
    //   var(--integral-blue) 45%
    // );
    // necessary for pdf render
    background: linear-gradient(45deg, var(--red-light) 30%, var(--purple) 50%, var(--blue) 80%);
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }

  // -360deg -> -180deg

  .line-container,
  .negative-color {
    background-color: $gray-200;
    left: 0;
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
  }

  .line-container {
    background: none;

    .line {
      border-bottom: 2px solid $gray-600;
      height: 100%;
      width: 50%;
    }
  }

  .middle-circle {
    background: $white;
    border-radius: 50%;
    position: absolute;
  }
}
</style>

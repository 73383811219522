<template>
  <b-jumbotron
    v-if="content"
    bg-variant="primary"
    text-variant="white"
    :fluid="true"
    :style="`background-image: url(${content.image})`"
    header-level="5"
  >
    <template #header>
      <div class="mb-4">{{ content.header }}</div>
    </template>
    <template #lead>{{ content.copy }}</template>
  </b-jumbotron>
</template>

<script>
export default {
  name: 'banner',
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.jumbotron {
  background-position: center;
  background-size: cover;

  * {
    color: $white;
  }

  @media only screen and (min-width: map-get($grid-breakpoints, 'md')) {
    ::v-deep h1,
    ::v-deep p {
      max-width: 70%;
    }
  }
}
</style>

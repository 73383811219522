<template>
  <span @mouseover="outline = false" @mouseleave="outline = true">
    <icon
      :id="id"
      :name="`information-${outline ? 'solid' : 'outline'}`"
      :size="size"
      :color="!outline ? hoverColor : color"
      class="align-top"
    />
    <b-tooltip :target="id" variant="integral-blue"><slot /></b-tooltip>
  </span>
</template>

<script>
export default {
  name: 'info-tip',
  props: {
    size: {
      type: String,
      default: '22px',
    },
    color: {
      type: String,
      default: 'var(--yellow)',
    },
    hoverColor: {
      type: String,
      default: 'var(--yellow)',
    },
  },
  data() {
    return {
      id: this._uid.toString(),
      outline: true,
    }
  },
}
</script>

<template>
  <div v-if="content.length" ref="content">
    <div v-for="(slide, index) of content" :key="index" class="accordion">
      <header v-b-toggle="`accordion-${index}`">
        <icon
          name="chevron-thin-right"
          size="24px"
          color="var(--black)"
          class="mr-3"
          :animation="visible[index] ? 'rotate-rt 0.2s linear forwards' : 'rotate-lt 0.2s linear forwards'"
        />
        {{ slide.header }}
      </header>
      <b-collapse :id="`accordion-${index}`" v-model="visible[index]" accordion="my-accordion" role="tabpanel">
        <div v-if="slide.body" class="body" v-html="slide.body" />
        <ul v-if="slide.links" class="body-links">
          <li v-for="r in slide.links" :key="r.title">
            <span v-html="r.intro"></span>&nbsp;
            <b-link v-if="r.to" :to="r.to">{{ r.label }}</b-link>
            <b-link
              v-else-if="r.link"
              class="ml-2"
              :href="r.link.pdf || r.link.internalLink || r.link.externalLink"
              :target="r.link.pdf || r.link.externalLink ? '_blank' : ''"
              >{{ r.link.label }}</b-link
            >
            <span v-else>{{ r.label }}</span>
          </li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accordion',
  props: {
    content: {
      type: Array,
      default: null,
    },
    open: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: [this.open],
    }
  },
  methods: {
    contentRef() {
      return this.$refs.content
    },
  },
}
</script>

<style lang="scss" scoped>
.accordion {
  margin-bottom: 5px;

  header {
    align-items: center;
    background-color: $gray-100;
    border-radius: $border-radius;
    display: flex;
    font-size: 25px;
    margin-bottom: 0;
    outline: none;
    padding: 24px 30px;

    @media only screen and (max-width: map-get($grid-breakpoints, 'md')) {
      padding: 24px 16px;
    }

    &.not-collapsed {
      border-bottom-left-radius: 0;
    }

    &.not-collapsed,
    &:hover {
      background-color: $blue-light;
    }
  }

  .collapse {
    background-color: $blue-light;
    border-bottom-left-radius: $border-radius;
  }

  .body-links,
  .body {
    margin-bottom: 0;
    padding: 0 72px 30px;

    @media only screen and (max-width: map-get($grid-breakpoints, 'md')) {
      padding: 0 58px 26px;
    }
  }
}
</style>

<style lang="scss">
.accordion {
  .body {
    .table {
      th,
      td,
      thead th {
        border-color: $blue;
      }
    }
  }
}
</style>

<template>
  <div class="outer">
    <div class="inner" :style="`width: ${innerWidth}%`"></div>
  </div>
</template>

<script>
export default {
  name: 'step-rule',
  props: {
    current: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      startTimestamp: 0,
      innerWidth: 0,
      duration: 250,
      start: 0,
    }
  },
  computed: {
    percent() {
      const width = (this.current / this.total) * 100
      return width > 100 ? 100 : width
    },
    durationFromPercent() {
      return (this.duration * this.percent) / 75
    },
  },
  watch: {
    percent(newValue, oldValue) {
      this.start = oldValue
      this.startTimestamp = 0
      window.requestAnimationFrame(this.step)
    },
  },
  mounted() {
    this.innerWidth = this.percent
    window.requestAnimationFrame(this.step)
  },
  methods: {
    step(timestamp) {
      if (!this.startTimestamp) this.startTimestamp = timestamp
      const progress = Math.min((timestamp - this.startTimestamp) / this.durationFromPercent, 1)
      this.innerWidth = Math.floor(progress * (this.percent - this.start) + this.start)
      if (progress < 1) {
        window.requestAnimationFrame(this.step)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.outer {
  background-color: $gray-300;
  height: 2px;
  position: relative;
  width: 100%;

  .inner {
    background-color: $primary;
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
  }
}
</style>

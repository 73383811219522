<template>
  <div class="page-footer" :class="{ simple }">
    <b-container v-if="!simple" class="top" fluid="lg">
      <b-row class="mb-4">
        <b-col>
          <logo :height="60" variant="light" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="8" md="4" lg="4" xl="3">
          <h6>Calgary</h6>
          <span class="address" v-html="address"></span>
        </b-col>
        <b-col v-for="column of footerMenu" :key="column.header" sm>
          <h6>{{ column.header }}</h6>
          <ul>
            <li v-for="(link, index) of column.links" :key="`${column.header}-${index}`">
              <a :href="link.link">{{ link.label }}</a>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <hr :style="{ borderColor: sizeColor }" class="mb-0" />
    <b-container class="bottom" :class="{ ['logged-in-container']: loggedIn || simple }" fluid>
      <b-row align-v="center" class="flex-column flex-sm-row mt-2">
        <b-col class="text-center text-sm-left mb-3 mb-sm-0">
          <a v-if="simple" href="http://www.integralorg.ca" class="simple-logo">
            <logo :simple="true" :height="40" />
            <span>IntegralOrg &copy;{{ new Date().getFullYear() }}</span>
          </a>
          <div v-else class="small">IntegralOrg - {{ new Date().getFullYear() }}</div>
        </b-col>
        <b-col class="text-center text-sm-right social">
          <a v-for="link of socialLinks" :key="link.label" :href="link.link" target="_blank" :title="link.label">
            <icon :name="link.icon" :color="sizeColor" size="18px" />
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'page-footer',
  computed: {
    ...mapGetters({
      loggedIn: 'account/loggedIn',
    }),
    simple() {
      return (
        !['home', 'toolkit', 'risk-management-toolkit', 'social-enterprise-toolkit'].includes(this.$route.name) ||
        this.loggedIn
      )
    },
    sizeColor() {
      return this.simple ? 'var(--gray-500)' : 'var(--white)'
    },
    address() {
      return this.$store.getters['ui/address']
    },
    socialLinks() {
      return this.$store.getters['ui/socialLinks'] || []
    },
    footerMenu() {
      return this.$store.getters['ui/footerMenu'] || []
    },
  },
}
</script>

<style lang="scss" scoped>
.page-footer {
  padding: 30px 0 0;

  &:not(.simple) {
    background-color: $integral-blue;
    color: $white;

    .social {
      a {
        .icon {
          color: $white;
        }

        &:hover {
          .icon {
            color: $gray-300 !important;
          }
        }
      }
    }
  }

  hr {
    opacity: 0.5;
  }

  .top {
    margin-top: 22px;

    h6 {
      font-weight: $font-weight-bold;
      margin-bottom: 25px;
      text-transform: uppercase;
    }

    .address p {
      margin-bottom: 30px;
    }

    ul {
      list-style: none;
      margin-bottom: 30px;
      padding: 0;

      a {
        color: $white;

        &:hover {
          color: $gray-300;
          text-decoration: none;
        }
      }
    }
  }

  .bottom {
    padding: 1rem;

    &.logged-in-container {
      background-color: $white;
    }
  }

  .simple-logo {
    align-items: center;
    display: flex;
    text-decoration: none;

    span {
      color: $gray-500;
      font-size: $font-size-sm;
      margin-left: 10px;
    }

    &:hover {
      span {
        color: $gray-600;
      }
    }

    @media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {
      display: block;
    }
  }

  .social {
    a {
      display: inline-block;
      margin: 0 8px;
      text-decoration: none;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        .icon {
          color: $primary !important;
        }
      }
    }
  }
}
</style>

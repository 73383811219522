// import axios from 'axios'
import Vue from 'vue'

import { toFormData } from '../utils'

// const csrf = {
// [window.data?.csrfTokenName]: window.data?.csrfTokenValue,
// 'x-craft-preview': craftPreview
// }

// const getFirstResponseError = errors => errors?.[Object.keys(errors)[0]][0]

// const responseSuccess = response =>
//   Promise.resolve({
//     ok: !response.data.error,
//     error: response.data.error || getFirstResponseError(response.data.errors),
//     ...response,
//   })

// const responseFailure = error =>
//   Promise.resolve({
//     error: error?.response?.data?.error || 'Connection error, please try again later!',
//     ...error.response,
//   })

// const create = () => {
//   const api = axios.create({
//     baseURL: 'http://integral-vue.nitro/',
//     withCredentials: false,
//     headers: {
//       Accept: 'application/json',
//       // 'Content-Type': 'application/x-www-form-urlencoded'
//       'Content-Type': 'multipart/form-data',
//     },
//   })
//   api.interceptors.response.use(responseSuccess, responseFailure)
//   return api
// }

export const post = (uri, params) =>
  Vue.axios.post(uri, toFormData({ ...JSON.parse(localStorage.getItem('csrf')), ...params }))

export const get = uri => Vue.axios.get(uri)

export const saveEntryConfig = sectionId => ({
  sectionId,
  action: 'entries/save-entry',
})

export const editEntryConfig = entryId => ({
  entryId,
  action: 'entries/save-entry',
})

export const deleteEntryConfig = entryId => ({
  entryId,
  action: 'entries/delete-entry',
})

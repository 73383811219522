// import $store from '../store/index'
import * as API from './Api'

/*
USAGE:
import AccountService from '../js/services/AccountService'
...
methods: {
    async myFunction () {
      const response = await AccountService.registerUser(formData)
      if (response.ok) {
        // do something
      }
    }
}
*/
export default {
  // SETTERS
  registerUser(payload) {
    return API.post('/', { ...payload, action: 'users/save-user' })
  },
  signIn(payload) {
    return API.post('/', { ...payload, action: '/users/login' })
  },
  editUser(payload, userId) {
    return API.post('/', { ...payload, userId, action: 'users/save-user' })
  },
  editPassword(payload) {
    return API.post('/', { ...payload, action: 'users/set-password' })
  },
  sendPasswordResetEmail(payload) {
    return API.post('/', { ...payload, action: 'users/send-password-reset-email' })
  },

  // GETTERS
  getCurrentUser(userId = '') {
    return API.get(`/api/get-user/${userId}`)
  },
  getAccountDetailCategories() {
    return API.get('/api/get-account-detail-categories/')
  },
}

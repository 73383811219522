import AccountService from '../../services/Account.service'

const defaultState = {
  user: null,
  categories: null,
}

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, {
      ...defaultState,
    })
  },
  SET_USER: (state, payload) => {
    state.user = payload
  },
  SET_ACCOUNT_DETAIL_CATEGORIES: (state, payload) => {
    state.categories = payload
  },
}

const actions = {
  RESET_STATE: ({ commit }) => {
    console.log('RESET_STATE')
    commit('RESET_STATE')
    commit('socialenterprise/RESET_STATE', null, { root: true })
    commit('riskmanagement/RESET_STATE', null, { root: true })
  },
  SIGN_IN: async ({ dispatch }, payload) => {
    const response = await AccountService.signIn(payload)
    if (response.ok) {
      await dispatch('LOAD_CURRENT_USER')
      await dispatch('riskmanagement/LOAD_QUESTIONNAIRES', null, { root: true })
    }
    return response
  },
  REGISTER_USER: async (_, payload) => {
    const response = await AccountService.registerUser(payload)
    return response
  },
  SEND_PASSWORD_RESET: async (_, payload) => {
    const response = await AccountService.sendPasswordResetEmail(payload)
    return response
  },
  SET_NEW_PASSWORD: async (_, payload) => {
    const response = await AccountService.editPassword(payload)
    return response
  },
  CHANGE_PASSWORD: async ({ state }, payload) => {
    const response = await AccountService.editUser(payload, state.user.id)
    return response
  },
  EDIT_USER: async ({ state, dispatch }, payload) => {
    const response = await AccountService.editUser(payload, state.user.id)
    if (response.ok) {
      await dispatch('LOAD_CURRENT_USER')
    }
    return response
  },
  LOAD_CURRENT_USER: async ({ commit }, userId) => {
    const response = await AccountService.getCurrentUser(userId)
    if (response.ok) {
      commit('SET_USER', response.data.user)
    }
  },
  LOAD_ACCOUNT_DETAIL_CATEGORIES: async ({ commit }) => {
    const response = await AccountService.getAccountDetailCategories()
    if (response.ok) {
      commit('SET_ACCOUNT_DETAIL_CATEGORIES', response.data)
    }
  },
}

const getters = {
  loggedIn: state => !!state.user?.id,
  hasAccountDetails: state => !!state.user?.organization,
  userId: state => state.user?.id,
  name: state => state.user?.name,
  avatar: state => state.user?.avatar,
  user: state => state.user,
  categories: state => state.categories,
  initials: state => {
    const name = state.user?.name
    if (!name?.first) return null
    return name.first.charAt(0) + name.last.charAt(0)
  },
}

export default {
  namespaced: true,
  state: { ...defaultState },
  mutations,
  actions,
  getters,
}

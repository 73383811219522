import Vue from 'vue'

import ToolkitsService from '@/services/Toolkits.service'

const state = {
  toolkits: null,
  currentToolkit: null,
  toolkitPages: [],
  definitions: [],
}

// Vue.set(state, 'address', payload)
const mutations = {
  SET_ALL_DATA: (state, payload) => {
    state.toolkits = payload.toolkits
    // state.definitions = payload.definitions
  },
  SET_CURRENT_TOOLKIT: (state, payload) => {
    state.currentToolkit = payload
  },
  SET_TOOLKIT_PAGES: (state, payload) => {
    state.toolkitPages = payload
  },
  RESET_STATE: state => {
    state.currentToolkit = null
    state.toolkitPages = []
  },
}

const actions = {
  LOAD_TOOLKITS: async ({ commit }) => {
    const response = await ToolkitsService.getToolkits()
    if (response.ok) {
      commit('SET_ALL_DATA', response.data)
    }
  },
  LOAD_TOOLKIT_PAGES: async ({ commit, getters }) => {
    const response = await ToolkitsService.getToolkitPages(getters.currentToolkit.id)
    if (response.ok) {
      commit('SET_TOOLKIT_PAGES', response.data)
    }
  },
  SET_LAST_OPENED: async ({ rootState, rootGetters }, payload) => {
    if (rootGetters['account/loggedIn']) {
      await ToolkitsService.setLastOpened(payload.mappedField, payload.date, rootState.account.user.id)
    }
  },
}

const getters = {
  toolkits: state => state.toolkits,
  toolkitPages: state => state.toolkitPages,
  currentToolkit: state => state.toolkits?.find(p => p.slug === state.currentToolkit),
  toolkitPageFromSlug: state => slug => state.toolkitPages.find(p => p.slug === slug),
  definitionFromId: (_, getters, rootState, rootGetters) => id =>
    rootGetters['socialenterprise/definitionFromId'](id) || rootGetters['riskmanagement/definitionFromId'](id),
  // definitionsInToolkit: state => toolkitSlug => state.definitions.filter(d => d.toolkit === toolkitSlug),
  // definitionLink: () => id => '/toolkit/definition/' + id,
  dashboardLink: state => state.toolkitPages.find(p => p.id === 221).uri,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

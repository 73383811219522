<template>
  <div class="idle-container hidden" data-testid="idle-timer-container">
    <div v-if="shouldTimeout" data-testid="idle-timer">
      <span>Timeout in:</span>
      <v-idle id="v-idle" :duration="duration" :loop="false" @idle="onidle" />
    </div>
    <div v-else data-testid="no-idle-timer">No timer necessary</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const timeoutMinutes = 60

export default {
  name: 'session-timeout',
  data() {
    return {
      duration: parseFloat(timeoutMinutes) * 60,
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'account/loggedIn',
    }),
    shouldTimeout() {
      return this.loggedIn
    },
  },
  methods: {
    onidle() {
      if (this.shouldTimeout) {
        console.log('SHOULD LOG OUT FROM SESSION')
        this.$store.dispatch('account/RESET_STATE')
        window.location.href = '/logout'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.idle-container {
  &.hidden {
    display: none;
  }

  > div {
    background-color: $gray-500;
    display: flex;
    justify-content: center;

    #v-idle {
      display: inline-block;
      margin-left: 5px;
    }
  }
}
</style>

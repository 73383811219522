import axios from 'axios'
import Vue from 'vue'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: 'http://integral-vue.nitro/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded'
    'Content-Type': 'multipart/form-data',
  },
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  config => {
    // Forward Craft Preview tokens to API
    if (!!window.craftPreview) {
      const params = {
        token: window.craftPreview.token,
        'x-craft-live-preview': window.craftPreview.preview,
      }
      if (!config.params) {
        config.params = params
      } else {
        Object.keys(params).map(key => (config.params[key] = params[key]))
      }
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

_axios.interceptors.request.use(
  config =>
    // Do something before request is sent
    config,
  error =>
    // Do something with request error
    Promise.reject(error)
)

const getFirstResponseError = errors => errors?.[Object.keys(errors)[0]][0]

// Add a response interceptor
_axios.interceptors.response.use(
  response =>
    Promise.resolve({
      ok: !response.data.error,
      error: response.data.error || getFirstResponseError(response.data.errors),
      ...response,
    }),
  error =>
    Promise.resolve({
      error: error?.response?.data?.error || 'Connection error, please try again later!',
      ...error.response,
    })
)

Plugin.install = function (Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      },
    },
    $axios: {
      get() {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin

<template>
  <div class="mb-5 pb-4">
    <div class="top mb-5">
      <p v-if="questionnaire.title" class="title">{{ questionnaire.title }}</p>
    </div>
    <div class="mx-0 mx-md-4 mb-4">
      <b-row cols="1" cols-md="2" class="details">
        <b-col class="mb-5">
          <donut :percent="score" :thickness="38" :size="220" :hide-info="true" />
        </b-col>
        <b-col>
          <h3>{{ questionnaire.title }}</h3>
          <span v-if="hasCompletedQuestionnaire" v-html="capacity.description"></span>
          <p v-else>Results will be given once you complete the quiz.</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'results',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    buttonLabel: {
      type: String,
      default: 'Done',
    },
    questionnaire: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      capacityOfQuestionnaire: 'riskmanagement/capacityOfQuestionnaire',
      percentScoreOnQuestionnaire: 'riskmanagement/percentScoreOnQuestionnaire',
      hasCompletedQuestionnaire: 'riskmanagement/hasCompletedQuestionnaire',
    }),
    capacity() {
      return this.capacityOfQuestionnaire(this.questionnaire)
    },
    score() {
      return this.hasCompletedQuestionnaire ? this.percentScoreOnQuestionnaire(this.questionnaire) : 0
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: $gray-600;
}

.top {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <div class="icon" :class="`icon-${name}`" :style="{ color, fontSize: size, animation }" data-testid="icon"></div>
</template>

<script>
export default {
  name: 'icon',
  props: {
    color: {
      type: String,
      default: 'var(--primary)',
    },
    size: {
      type: String,
      default: '24px',
    },
    name: {
      type: String,
      required: true,
    },
    animation: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
}
</style>

// import $store from '../store/index'
import * as API from './Api'

/*
USAGE:
import AccountService from '../js/services/AccountService'
...
methods: {
    async myFunction () {
      const response = await AccountService.registerUser(formData)
      if (response.data.length) {
        // do something
      }
    }
}
*/
export default {
  // SETTERS
  // registerUser(postData) {
  //   return API.post('/account/register', postData)
  // },
  addAnswerEntry(postData) {
    return API.post('/', { ...postData, ...API.saveEntryConfig(7) })
  },
  removeEntry(entryId) {
    return API.post('/', { ...API.deleteEntryConfig(entryId) })
  },
  editEntryTitle(sessionId, title) {
    return API.post('/', { title, ...API.editEntryConfig(sessionId) })
  },
  addAnswerSessionEntry(postData) {
    return API.post('/', { ...postData, ...API.saveEntryConfig(8) })
  },
  makeAnswerSessionComplete(sessionId, resultId) {
    return API.post('/', { fields: { completed: 1, result: [resultId] }, ...API.editEntryConfig(sessionId) })
  },
  setAnswer(allAnswers, fieldHandle, userId) {
    return API.post('/', {
      fields: { [fieldHandle]: JSON.stringify(allAnswers) },
      userId,
      action: 'users/save-user',
    })
  },
  clearAnswers(fieldHandle, userId) {
    return API.post('/', {
      fields: { [fieldHandle]: '' },
      userId,
      action: 'users/save-user',
    })
  },
  setLastOpened(fieldHandle, date, userId) {
    return API.post('/', {
      fields: { [fieldHandle]: { ['date']: date, ['timezone']: 'America/Edmonton' } },
      userId,
      action: 'users/save-user',
    })
  },
  // editPassword(postData, userId) {
  //   return API.post('/', { ...postData, ...API.editUserConfig(userId) })
  // },

  // GETTERS
  getGeneralAssessment() {
    return API.get('/api/risk-management/get-general-assessment')
  },
  getToolkitPages(toolkitId) {
    return API.get(`/api/get-toolkit-pages/${toolkitId}`)
  },
  getToolkits() {
    return API.get('/api/get-toolkits')
  },
  getRiskManagementToolkit() {
    return API.get(`/api/get-risk-management`)
  },
  getRiskManagementToolkitQuestionnaires(userId) {
    return API.get(`/api/get-risk-management-questionnaires/${userId}`)
  },
  getRiskManagementToolkitResources() {
    return API.get('/api/get-risk-management-resources')
  },
  getSocialEnterpriseToolkit() {
    return API.get('/api/get-social-enterprise')
  },
  getSocialEnterpriseToolkitAnswers() {
    return API.get('/api/get-social-enterprise-answers')
  },
  // getTeamsInDept (deptId) {
  //   return API.get('/api/teams-in-dept/' + deptId)
  // },
}

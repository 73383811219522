<template>
  <div>
    <div v-if="triangle" class="triangle" :class="color" :style="`left: calc(${displayedPercent}% - 9px);`"></div>
    <div v-else class="indicator" :style="indicatorStyle">{{ parseInt(displayedPercent, 10) }}%</div>
    <div class="progress">
      <div class="bar" :style="`width: ${displayedPercent}%; background-color: var(--${color})`"></div>
    </div>
    <div v-if="showRisk" class="risk">
      <div>{{ lowLabel }}</div>
      <div>{{ highLabel }}</div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'progress-bar',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 1.25,
    },
    delay: {
      type: Number,
      default: 0,
    },
    triangle: {
      type: Boolean,
      default: false,
    },
    showRisk: {
      type: Boolean,
      default: false,
    },
    lowLabel: {
      type: String,
      default: 'Low capacity',
    },
    highLabel: {
      type: String,
      default: 'High capacity',
    },
  },
  data() {
    return {
      displayedPercent: 0,
      startTimestamp: null,
    }
  },
  computed: {
    indicatorStyle() {
      const style = `color: var(-${this.color});`
      if (this.displayedPercent < 5) {
        return style
      }
      if (this.displayedPercent > 95) {
        return `${style}float: right`
      }
      return `${style}left: calc(${this.displayedPercent}% - 15px)`
    },
    color() {
      if (!this.triangle) {
        return 'purple'
      }
      if (this.displayedPercent === 0) {
        return 'gray'
      }
      if (this.displayedPercent <= 25) {
        return 'red'
      }
      if (this.displayedPercent <= 50) {
        return 'orange'
      }
      if (this.displayedPercent <= 75) {
        return 'blue'
      }
      return 'green'
    },
  },
  watch: {
    value(newValue, oldValue) {
      this.displayedPercent = this.value
    },
    triangle(newValue, oldValue) {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.startTimestamp = null

      gsap.to(this, { displayedPercent: this.value, duration: this.duration, delay: this.delay, ease: 'power2.inOut' })
    },
  },
}
</script>

<style lang="scss" scoped>
.indicator {
  color: $purple;
  line-height: 16px;
  margin-bottom: 5px;
  position: relative;
}

.triangle {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid;
  height: 0;
  margin-top: 5px;
  position: relative;
  width: 0;

  &.gray {
    border-top-color: $gray-400;
  }

  &.blue {
    border-top-color: $blue;
  }

  &.orange {
    border-top-color: $orange;
  }

  &.red-light {
    border-top-color: $red-light;
  }

  &.green {
    border-top-color: $green;
  }
}

.progress {
  background-color: $gray-200;
  border-radius: 2px;
  height: 4px;
  width: 100%;

  .bar {
    border-radius: 2px;
    height: 4px;
  }
}

.risk {
  color: $gray-500;
  display: flex;
  font-size: $font-size-sm;
  justify-content: space-between;
  margin-top: 5px;
}
</style>

<template>
  <b-alert :show="!!message" variant="danger"><strong>Error:</strong> {{ message }}</b-alert>
</template>

<script>
export default {
  name: 'inline-error',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
}
</script>

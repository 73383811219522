<template>
  <b-card class="card-container mb-5">
    <div class="card-content" :class="{ 'is-pdf': isPdf }">
      <h3 class="mb-0">{{ title }}</h3>
      <div class="donut-description">
        <donut
          v-observe-visibility="{
            callback: onGaugeVisible,
            once: true,
          }"
          :show="!isDetailsShowing && isGaugeVisible"
          class="w-100"
          :class="{ 'my-4': !isPdf }"
          :percent="percentScore"
          :thickness="34"
          :size="180"
          :is-pdf="isPdf"
          :hide-info="isPdf"
          @info="showDetailsView"
        />
        <div v-if="!isPdf" class="w-100">
          <strong v-if="questionnaire.comingSoon" class="coming-soon">Coming soon!</strong>
          <cta-button v-else-if="!started" variant="outline-primary" size="md" :to="to">Take quiz</cta-button>
          <div v-else class="ctas w-100">
            <template v-if="completed">
              <div class="completed-ctas">
                <b-link :to="{ name: 'rm-result', params: { questionnaireId: questionnaire.id } }"
                  >Review answers</b-link
                >
                <b-link v-if="loggedIn" :to="to" @click="onRetake">Retake quiz</b-link>
              </div>
            </template>
            <template v-else>
              <cta-button class="mb-2" variant="outline-primary" size="md" :to="to" @click="onComplete"
                >Complete quiz</cta-button
              >
              <b-link class="d-block text-muted" :to="to" @click="onRestart">Restart</b-link>
            </template>
          </div>
        </div>
        <div v-else>
          <p v-if="completed" v-html="capacity.description"></p>
        </div>
      </div>
    </div>

    <div v-if="completed" ref="details" class="card-completion">
      <b-link class="close" @click.prevent="hideDetailsView"
        ><span>close</span><icon name="clear" size="18px" color="var(--white)"
      /></b-link>
      <div class="content">
        <h6 class="mb-3">
          <strong>{{ title }}</strong>
        </h6>
        <h6 class="mb-3">
          Quiz result: <strong>{{ capacity.title }}</strong>
        </h6>
        <span v-html="capacity.description"></span>
      </div>
    </div>
  </b-card>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters } from 'vuex'

import Donut from './Donut'

export default {
  name: 'dashboard-card',
  components: {
    Donut,
  },
  props: {
    questionnaire: {
      type: Object,
      default: () => {},
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: this.questionnaire.title,
      started: !!this.questionnaire.answers,
      isDetailsShowing: false,
      isGaugeVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      assessmentLink: 'riskmanagement/assessmentLink',
      capacityFromPercent: 'riskmanagement/capacityFromPercent',
      percentScoreOnQuestionnaire: 'riskmanagement/percentScoreOnQuestionnaire',
      filteredSlidesInQuestionnaire: 'riskmanagement/filteredSlidesInQuestionnaire',
      loggedIn: 'account/loggedIn',
    }),
    to() {
      return this.assessmentLink(this.questionnaire.slug)
    },
    completed() {
      return this.filteredSlidesInQuestionnaire(this.questionnaire)
        .filter(s => !s.intro)
        .every(q => q.options.some(a => this.questionnaire?.answers?.[q.id] === a.id.toString()))
    },
    percentScore() {
      return this.completed ? this.percentScoreOnQuestionnaire(this.questionnaire) : 0
    },
    capacity() {
      return this.capacityFromPercent(this.percentScore)
    },
  },
  methods: {
    onRetake() {},
    onRestart() {
      this.$store.dispatch('riskmanagement/RESET_ANSWERS_IN_QUESTIONNAIRE', this.questionnaire.id)
    },
    onComplete() {},
    showDetailsView() {
      if (this.completed) {
        gsap.to(this.$refs.details, { bottom: 0, duration: 0.6, ease: 'power3.out' }).then(() => {
          this.isDetailsShowing = true
        })
      }
    },
    hideDetailsView() {
      if (this.completed) {
        this.isDetailsShowing = false
        gsap.to(this.$refs.details, { bottom: '-100%', duration: 0.5, ease: 'power3.in' })
      }
    },
    onGaugeVisible(isVisible) {
      if (isVisible) {
        this.isGaugeVisible = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
h3 {
  min-height: 84px;
}

.card-container {
  overflow: hidden;
  position: relative;
}

.card-body {
  background: $white;
  padding: 10px 30px;
}

.card-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 15px 0;
  text-align: center;

  &.is-pdf {
    justify-content: flex-start;

    h3 {
      min-height: 60px;
    }

    .donut-description {
      display: flex;
      text-align: left;

      .donut-container {
        min-width: 220px;
      }
    }
  }
}

.ctas {
  font-size: $font-size-sm;
}

.completed-ctas {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;

  a {
    color: $gray-700;
    display: inline-block;
    text-decoration: none;

    &:hover {
      color: $primary;
    }
  }
}

.coming-soon {
  color: $gray-700;
}

.card-completion {
  background: $gray-600;
  border-radius: $border-radius;
  bottom: -100%;
  color: $white;
  height: 100%;
  left: 0;
  padding: 15px;
  position: absolute;
  width: 100%;

  .close {
    color: $white;
    display: flex;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    position: absolute;
    right: 10px;
    text-shadow: none;
    top: 10px;

    span {
      margin-right: 5px;
    }
  }

  .content {
    margin: 10px;
  }
}
</style>

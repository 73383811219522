import Vue from 'vue'
import Vuex from 'vuex'
// import VueMediaEmbed from 'vue-media-embed'
import createPersistedState from 'vuex-persistedstate'

import modules from './modules'

Vue.use(Vuex)
// Vue.config.devtools = true

const store = new Vuex.Store({
  modules,
  strict: false,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
})

// Vue.use(VueMediaEmbed, { store })

export default store

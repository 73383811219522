<template>
  <div class="page-header">
    <div v-if="!isHiddenMenu" class="top-bar"></div>
    <b-navbar toggleable="lg">
      <b-navbar-brand to="/" class="ml-4"><logo /></b-navbar-brand>
      <main-nav :authenticated="authenticated" />
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'page-header',
  computed: {
    authenticated() {
      return this.$store.getters['account/loggedIn']
    },
    isHiddenMenu() {
      return (
        this.$route.path.includes('questionnaire') ||
        this.$route.path.includes('case-study') ||
        this.$route.path.includes('definition')
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.page-header {
  .top-bar {
    background-color: $red;
    height: 10px;
    width: 100%;
  }

  .navbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }
}
</style>

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import {
  BAlert,
  BAvatar,
  BButton,
  BCol,
  BCollapse,
  BContainer,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BImg,
  BJumbotron,
  BLink,
  BNav,
  BNavbar,
  BNavbarBrand,
  BNavbarNav,
  BNavbarToggle,
  BNavItem,
  BOverlay,
  BRow,
  BToast,
  CardPlugin,
  FormPlugin,
  FormSelectPlugin,
  InputGroupPlugin,
  ModalPlugin,
  ToastPlugin,
  TooltipPlugin,
  VBToggle,
} from 'bootstrap-vue'
import Vue from 'vue'

Vue.use(CardPlugin)
Vue.use(FormPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ModalPlugin)
Vue.use(TooltipPlugin)
Vue.use(InputGroupPlugin)
Vue.use(ToastPlugin)

// directives
Vue.directive('b-toggle', VBToggle)

Vue.component('b-alert', BAlert)
Vue.component('b-avatar', BAvatar)
Vue.component('b-button', BButton)
Vue.component('b-col', BCol)
Vue.component('b-collapse', BCollapse)
Vue.component('b-container', BContainer)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-form', BForm)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-radio', BFormRadio)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-img', BImg)
Vue.component('b-jumbotron', BJumbotron)
Vue.component('b-link', BLink)
Vue.component('b-nav', BNav)
Vue.component('b-navbar', BNavbar)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-navbar-toggle', BNavbarToggle)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-overlay', BOverlay)
Vue.component('b-row', BRow)
Vue.component('b-toast', BToast)

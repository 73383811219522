export const toFormData = (obj, form, namespace) => {
  const fd = form || new FormData()
  let formKey

  for (const property in obj) {
    // if (obj.hasOwnProperty(property) && obj[property]) {
    if (namespace) {
      formKey = `${namespace}[${property}]`
    } else {
      formKey = property
    }

    // if the property is an object, but not a File, use recursivity.
    if (obj[property] instanceof Date) {
      fd.append(formKey, obj[property].toISOString())
    } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      toFormData(obj[property], fd, formKey)
    } else {
      // if it's a string or a File object
      fd.append(formKey, obj[property])
    }
  }

  return fd
}

export const copyLinkToClipboard = selector => {
  let testingCodeToCopy = document.querySelector(selector)
  testingCodeToCopy.setAttribute('type', 'text')
  testingCodeToCopy.select()

  try {
    document.execCommand('copy')
    alert('Sharable link was copied to your clipboard')
  } catch (err) {
    alert('Oops, unable to copy')
  }

  testingCodeToCopy.setAttribute('type', 'hidden')
  window.getSelection().removeAllRanges()
}

// make any refs containing elements that have anchors use the router instead of loading a new page
export const anchorsToRouter = (refContainer, router) => {
  if (refContainer) {
    const anchors = refContainer.querySelectorAll('a')
    anchors.forEach(a => {
      a.addEventListener('click', event => {
        if (a.href.includes(location.host)) {
          event.preventDefault()
          const relativeRoute = new URL(a.href).pathname
          router.push(relativeRoute)
        }
      })
    })
  }
}

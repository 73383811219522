<template>
  <div class="main-nav">
    <b-navbar-toggle target="nav-collapse" class="hamburger mr-4">
      <icon name="menu" size="24px" color="var(--gray-700)" />
    </b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav class="mr-4">
      <b-navbar-nav v-if="!isHiddenMenu" class="menu">
        <b-nav v-if="authenticated && $route.name !== 'home'">
          <b-nav-item :to="{ name: 'home' }">Toolkits</b-nav-item>
          <template v-for="page in pages">
            <b-nav-item v-if="!page.hideFromMenu" :key="page.id" :to="page.uri">{{
              page.menuLabel || page.title
            }}</b-nav-item>
          </template>
          <b-nav-item v-if="authenticated" class="account-link" :to="{ name: 'edit-details' }"
            >Account Settings <icon class="d-inline" name="chevron-small-down" size="14px"
          /></b-nav-item>
          <b-nav-item v-if="authenticated" class="account-link" @click="onLogout"
            >Logout <icon class="d-inline" name="chevron-small-down" size="14px"
          /></b-nav-item>
        </b-nav>
        <b-nav v-else>
          <b-nav-item v-for="link of links" :key="link.label" :href="link.link">
            {{ link.label }} <icon class="d-inline" name="chevron-small-down" size="14px" />
          </b-nav-item>
          <b-nav-item v-if="authenticated" class="account-link" :to="{ name: 'edit-details' }"
            >Account Settings <icon class="d-inline" name="chevron-small-down" size="14px"
          /></b-nav-item>
          <b-nav-item v-if="authenticated" class="account-link" @click="onLogout"
            >Logout <icon class="d-inline" name="chevron-small-down" size="14px"
          /></b-nav-item>
        </b-nav>
        <avatar v-if="authenticated" class="avatar" />
        <cta-button v-else class="text-uppercase" :to="{ name: 'sign-in' }" variant="outline-primary">
          Sign in
        </cta-button>
      </b-navbar-nav>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'main-nav',
  props: {
    authenticated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      // toolkitPageFromSlug: 'toolkits/toolkitPageFromSlug',
      toolkitPages: 'toolkits/toolkitPages',
      headerMenu: 'ui/headerMenu',
    }),
    pages() {
      if (this.$route.name === 'social-enterprise-toolkit' || this.$route.path.includes('social-enterprise')) {
        return []
      }
      return this.toolkitPages
    },
    links() {
      return this.headerMenu
    },
    isHiddenMenu() {
      return (
        this.$route.path.includes('questionnaire') ||
        this.$route.path.includes('case-study') ||
        this.$route.path.includes('definition')
      )
    },
  },
  methods: {
    onLogout() {
      this.$store.dispatch('account/RESET_STATE')
      window.location.href = '/logout'
    },
  },
}
</script>

<style lang="scss" scoped>
#nav-collapse {
  flex-grow: 0;
}

.menu {
  align-items: center;
  display: flex;
  justify-content: center;

  > :last-child {
    margin-left: 20px;
  }

  ::v-deep .btn {
    padding: $input-btn-padding-y-lg * 1.5 $input-btn-padding-x-lg;
    white-space: nowrap;
  }

  .nav {
    justify-content: center;
    list-style: none;
    margin: 0;

    .nav-item {
      display: inline-block;
      margin: 0 20px;

      &.account-link {
        display: none;

        @media only screen and (max-width: map-get($grid-breakpoints, 'lg')) {
          display: inline-block;
        }
      }

      @media only screen and (max-width: map-get($grid-breakpoints, 'md')) {
        margin: 0 5px;
      }
    }

    a {
      border-bottom: 2px solid transparent;
      color: $gray-700;
      font-size: $font-size-sm;
      padding: 5px 0;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        border-bottom-color: $primary;
        color: $primary;
      }
    }
  }
}

.hamburger {
  border: none;
  position: absolute;
  right: 0;
  top: 40px;

  .icon {
    &:hover {
      color: $primary !important;
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, 'lg')) {
  .main-nav {
    width: 100%;
  }

  .avatar {
    display: none;
  }
}
</style>

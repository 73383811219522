export default {
  methods: {
    scrollToTop: () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      }),
  },
  created() {
    const { title } = this.$options
    if (title) {
      document.title = `${typeof title === 'function' ? title.call(this) : title} - IntegralOrg`
    }
  },
}

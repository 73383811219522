<template>
  <div class="mb-5 pb-4">
    <div class="top">
      <p v-if="questionnaire.title" class="title">{{ questionnaire.title }}</p>
      <b-link v-if="skip" class="text-muted" @click="$emit('skip')">Skip</b-link>
      <b-link v-else @click="$router.back()">
        <icon name="clear" size="32px" color="var(--dark)" />
      </b-link>
    </div>
    <div v-if="data.type && data.type !== 'question'" class="type mt-4">{{ data.type }}</div>
    <h2 class="mt-4">
      {{ data.title }}
      <span v-if="!!data.definitions">
        <info-tip v-for="definition in data.definitions" :key="definition" class="mr-2"
          >{{ definitionFromId(definition).tooltip }}
          <b-link size="sm" variant="outline-secondary" class="learn-more ml-1" @click="onDefinition(definition)"
            >Learn more</b-link
          ></info-tip
        >
      </span>
    </h2>
    <cta-button v-if="!!data.caseStudy" size="sm" variant="primary" @click="onCaseStudy">View case study</cta-button>
    <div class="ml-4 mt-4">
      <radio
        v-for="option in data.options"
        :key="option.id"
        :model="$attrs.value"
        :value="option.id.toString()"
        name="options"
        @input="$emit('input', arguments[0])"
      >
        {{ option.label }}
        <info-tip
          v-if="!!option.definition"
          :color="$attrs.value === option.id.toString() ? 'var(--blue)' : undefined"
          :hover-color="$attrs.value === option.id.toString() ? 'var(--integral-blue)' : undefined"
          >{{ definitionFromId(option.definition).tooltip }}
          <b-link size="sm" variant="outline-secondary" class="learn-more ml-1" @click="onDefinition(option.definition)"
            >Learn more</b-link
          ></info-tip
        >
      </radio>
      {{ model }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'question',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    questionnaire: {
      type: Object,
      default: () => {},
    },
    skip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      model: '',
    }
  },
  computed: {
    ...mapGetters({
      definitionFromId: 'toolkits/definitionFromId',
      currentToolkit: 'toolkits/currentToolkit',
    }),
  },
  methods: {
    onCaseStudy() {
      this.$router.push({ name: 'se-case-study', params: { caseStudyId: this.data.caseStudy, back: true } })
    },
    onDefinition(definitionId) {
      this.$router.push({
        name: `${this.currentToolkit.slug}-definition`,
        params: { definitionId: definitionId, back: true },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: $gray-600;
  margin-bottom: 0;
}

.type {
  color: $primary;
  font-size: 22px;
  margin-bottom: 0;
}

.top {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.learn-more {
  color: $white;
  text-decoration: underline;
  white-space: nowrap;

  &:hover {
    color: $gray-300;
  }
}
</style>

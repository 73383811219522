import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'

import Accordion from '@/components/Accordion.vue'
import Avatar from '@/components/Avatar.vue'
import BackButton from '@/components/BackButton.vue'
import Banner from '@/components/Banner.vue'
import BoldTitle from '@/components/BoldTitle.vue'
import Capacity from '@/components/Capacity.vue'
import Clipart from '@/components/Clipart.vue'
import CtaButton from '@/components/CtaButton.vue'
import DashboardCard from '@/components/DashboardCard.vue'
import Donut from '@/components/Donut.vue'
import EngagementChart from '@/components/EngagementChart.vue'
import Icon from '@/components/Icon.vue'
import InfoTip from '@/components/InfoTip.vue'
import InlineError from '@/components/InlineError.vue'
import InputField from '@/components/InputField.vue'
import Logo from '@/components/Logo.vue'
import MainNav from '@/components/MainNav.vue'
import PageFooter from '@/components/PageFooter.vue'
import PageHeader from '@/components/PageHeader.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import Intro from '@/components/questionnaire/Intro.vue'
import Question from '@/components/questionnaire/Question.vue'
import Results from '@/components/questionnaire/Results.vue'
import Radio from '@/components/Radio.vue'
import Recommendations from '@/components/Recommendations.vue'
import SelectField from '@/components/SelectField.vue'
import SessionTimeout from '@/components/SessionTimeout.vue'
import StepRule from '@/components/StepRule.vue'
import SuccessAlert from '@/components/SuccessAlert.vue'
import ToolkitCard from '@/components/ToolkitCard.vue'
import globalMixin from '@/mixins/globalMixin'

Vue.mixin(globalMixin)

Vue.use(VueObserveVisibility)

// custom components
Vue.component(Accordion.name, Accordion)
Vue.component(Avatar.name, Avatar)
Vue.component(BackButton.name, BackButton)
Vue.component(Banner.name, Banner)
Vue.component(BoldTitle.name, BoldTitle)
Vue.component(Capacity.name, Capacity)
Vue.component(Icon.name, Icon)
Vue.component(Clipart.name, Clipart)
Vue.component(CtaButton.name, CtaButton)
Vue.component(DashboardCard.name, DashboardCard)
Vue.component(Donut.name, Donut)
Vue.component(EngagementChart.name, EngagementChart)
Vue.component(InfoTip.name, InfoTip)
Vue.component(InlineError.name, InlineError)
Vue.component(InputField.name, InputField)
Vue.component(Logo.name, Logo)
Vue.component(MainNav.name, MainNav)
Vue.component(Radio.name, Radio)
Vue.component(Recommendations.name, Recommendations)
Vue.component(PageFooter.name, PageFooter)
Vue.component(PageHeader.name, PageHeader)
Vue.component(ProgressBar.name, ProgressBar)
Vue.component(Intro.name, Intro)
Vue.component(Question.name, Question)
Vue.component(Results.name, Results)
Vue.component(SelectField.name, SelectField)
Vue.component(SessionTimeout.name, SessionTimeout)
Vue.component(StepRule.name, StepRule)
Vue.component(SuccessAlert.name, SuccessAlert)
Vue.component(ToolkitCard.name, ToolkitCard)

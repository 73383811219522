<template>
  <div class="avatar" @mouseover="onOver" @mouseleave="onLeave">
    <b-dropdown id="dropdown-1" ref="dropdown" right class="m-2">
      <template #button-content>
        <b-avatar button variant="info" :text="initials" :src="image" size="60px"></b-avatar>
      </template>
      <b-dropdown-item :to="{ name: 'edit-details' }">Settings</b-dropdown-item>
      <b-dropdown-item @click="onLogout">Logout</b-dropdown-item>
    </b-dropdown>
    <div class="arrow"><icon name="chevron-small-down" size="16px" color="var(__black)" /></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'avatar',
  computed: {
    ...mapGetters({
      initials: 'account/initials',
    }),
    image() {
      return this.$store.getters['account/avatar']
    },
  },
  methods: {
    onOver() {
      this.$refs.dropdown.visible = true
    },
    onLeave() {
      this.$refs.dropdown.visible = false
    },
    onLogout() {
      this.$store.dispatch('account/RESET_STATE')
      window.location.href = '/logout'
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  display: inline-block;
  position: relative;

  ::v-deep .dropdown {
    .dropdown-menu {
      font-size: 14px;
    }

    .btn-info {
      background-color: lighten($primary, 40%);
      color: darken($primary, 20%);
    }

    .dropdown-toggle.btn-secondary {
      align-items: center;
      background-color: $gray-400;
      border: none;
      border-radius: 50%;
      color: $black;
      display: flex;
      font-size: 22px;
      height: 60px;
      justify-content: center;
      padding: 0;
      width: 60px;

      .b-avatar {
        padding: 0;
      }

      &:after {
        content: none;
      }
    }
  }

  .arrow {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    bottom: 7px;
    display: flex;
    height: 17px;
    justify-content: center;
    position: absolute;
    right: 7px;
    width: 17px;
    z-index: 1;
  }

  &:hover,
  &:active {
    cursor: pointer;

    // /deep/ .dropdown {
    //   .dropdown-toggle.btn-secondary {
    //     // background-color: $gray-500;
    //   }
    // }
  }
}
</style>

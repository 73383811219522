import Vue from 'vue'

import ToolkitsService from '@/services/Toolkits.service'

const state = {
  questionnaires: [],
  dashboard: {},
  gauges: [],
  capacities: [],
  recommendations: [],
  filters: [],
  definitions: [],

  currentSlideIndex: 0,
  currentAnswers: {},
  currentQuestionnaire: null,
}

const mutations = {
  SET_ALL_DATA: (state, payload) => {
    state.dashboard = payload.dashboard
    state.gauges = payload.gauges
    state.capacities = payload.capacities
    state.recommendations = payload.recommendations
    state.filters = payload.filters
    state.definitions = payload.definitions
  },
  SET_QUESTIONNAIRES: (state, payload) => {
    state.questionnaires = payload.questionnaires
  },
  SET_CURRENT_SLIDE_INDEX: (state, index) => {
    if (index < 0) index = 0
    state.currentSlideIndex = index
  },
  SET_ANSWER: (state, payload) => {
    if (state.questionnaires[payload.questionnaireIndex].answers) {
      Vue.set(state.questionnaires[payload.questionnaireIndex].answers, payload.id.toString(), payload.answer)
    } else {
      Vue.set(state.questionnaires[payload.questionnaireIndex], 'answers', { [payload.id.toString()]: payload.answer })
    }
  },
  SET_ANSWERS: (state, { questionnaireId, answers }) => {
    const questionnaireIndex = state.questionnaires.findIndex(q => q.id === questionnaireId)
    Vue.set(state.questionnaires[questionnaireIndex], 'answers', answers)
  },
  SET_CURRENT_QUESTIONNAIRE: (state, questionnaireSlug) => {
    state.currentQuestionnaire = questionnaireSlug
  },
  RESET_QUESTIONS: state => {
    state.currentSlideIndex = 0
    state.currentAnswers = {}
  },
  RESET_STATE: state => {
    state.currentSlideIndex = 0
    state.currentAnswers = {}
    state.currentQuestionnaire = null
  },
}

const actions = {
  LOAD_RISK_MANAGEMENT: async ({ commit, dispatch }) => {
    const response = await ToolkitsService.getRiskManagementToolkit()
    if (response.ok) {
      await commit('SET_ALL_DATA', response.data)
    }
  },
  LOAD_QUESTIONNAIRES: async ({ commit, dispatch }, userId = '') => {
    const response = await ToolkitsService.getRiskManagementToolkitQuestionnaires(userId)
    if (response.ok) {
      await commit('SET_QUESTIONNAIRES', response.data)
      await dispatch('PURGE_LEGACY_ANSWERS')
    }
  },
  RESET_ANSWERS_IN_QUESTIONNAIRE: async ({ commit, state, getters, rootState }, questionnaireId) => {
    commit('SET_ANSWERS', { questionnaireId, answers: null })
    await ToolkitsService.clearAnswers(getters.currentQuestionnaire.mappedField, rootState.account.user.id)
  },
  SET_ANSWER: async ({ commit, state, getters, rootState }, payload) => {
    const questionnaireId = getters.currentQuestionnaire.id
    const questionnaireIndex = state.questionnaires.findIndex(q => q.id === questionnaireId)
    await commit('SET_ANSWER', { ...payload.result, questionnaireIndex })
    await ToolkitsService.setAnswer(getters.currentAnswers, payload.mappedField, rootState.account.user.id)
  },
  SET_ANSWERS: async ({ commit, state, getters, rootState }, answers) => {
    const questionnaireId = getters.currentQuestionnaire.id
    if (answers) {
      await commit('SET_ANSWERS', { questionnaireId, answers })
      await ToolkitsService.setAnswer(answers, getters.currentQuestionnaire.mappedField, rootState.account.user.id)
    }
  },
  GET_RESOURCES: async ({ commit, state, getters, rootState }, answers) => {
    const result = await ToolkitsService.getRiskManagementToolkitResources()
    return result
  },
  SET_CURRENT_QUESTIONNAIRE: async ({ commit }, questionnaireSlug) => {
    commit('SET_CURRENT_QUESTIONNAIRE', questionnaireSlug)
  },
  PURGE_LEGACY_ANSWERS: async ({ commit, state, rootState }) => {
    for await (const q of state.questionnaires) {
      if (q.answers) {
        const accurateAnswersAry = Object.entries(q.answers)
          .filter(kv =>
            q.slides
              .filter(s => !s.intro)
              .some(s => s.id === parseInt(kv[0], 10) && s.options.some(o => o.id === parseInt(kv[1], 10)))
          )
          .map(kv => ({ question: parseInt(kv[0], 10), answer: kv[1] }))
        let answers = {}
        accurateAnswersAry.forEach(a => {
          answers[a.question] = a.answer
        })
        if (Object.keys(answers).length !== Object.keys(q.answers).length) {
          if (!Object.keys(answers).length) {
            answers = null
          }
          await commit('SET_ANSWERS', { questionnaireId: q.id, answers })
          await ToolkitsService.setAnswer(answers || '', q.mappedField, rootState.account.user.id)
        }
      }
    }
  },
}

const getters = {
  questionnaires: state => state.questionnaires,
  dashboard: state => state.dashboard,
  gauges: state => state.gauges,
  recommendations: state => state.recommendations,
  capacities: state => state.capacities,
  definitions: state => state.definitions,

  assessmentQuestionnaire: state => state.questionnaires.find(q => q.type === 'assessment' && q.slides.length),
  standardQuestionnaires: state => state.questionnaires.filter(q => q.type === 'standard' && q.slides.length),
  currentQuestionnaire: (state, getters) =>
    state.questionnaires.find(q => state.currentQuestionnaire?.includes(q.slug)),
  questionnaireById: state => id => state.questionnaires.find(q => q.id === id),

  assessmentLink: (_, getters, rootState, rootGetters) => slug =>
    '/' + rootGetters['toolkits/currentToolkit'].uri + '/questionnaire/' + slug,

  currentSlideIndex: state => state.currentSlideIndex,
  currentAnswers: (_, getters) => getters.currentQuestionnaire?.answers || {},
  hasStartedAssessment: (_, getters) => !!getters.assessmentQuestionnaire?.answers,
  hasCompletedAssessment: (_, getters) =>
    getters
      .filteredSlidesInQuestionnaire(getters.assessmentQuestionnaire)
      ?.filter(s => !s.intro)
      .every(q => q.options.some(a => getters.assessmentQuestionnaire.answers?.[q.id] === a.id.toString())),
  hasCompletedQuestionnaire: (_, getters) =>
    getters
      .filteredSlidesInQuestionnaire(getters.currentQuestionnaire)
      ?.filter(s => !s.intro)
      .every(q => q.options.some(a => getters.currentQuestionnaire.answers?.[q.id] === a.id.toString())),
  hasCompletedSpecificQuestionnaire: (_, getters) => questionnaire =>
    getters
      .filteredSlidesInQuestionnaire(questionnaire)
      ?.filter(s => !s.intro)
      .every(q => q.options.some(a => questionnaire.answers?.[q.id] === a.id.toString())),
  startedStandardQuestionnaires: (_, getters) => getters.standardQuestionnaires.filter(q => q.answers),
  hasStartedStandardQuestionnaires: (_, getters) => getters.standardQuestionnaires.some(q => q.answers),
  hasCompletedStandardQuestionnaires: (_, getters) =>
    !getters.standardQuestionnaires.some(q => !getters.hasCompletedSpecificQuestionnaire(q)),
  completedQuestionnaires: (_, getters) =>
    getters.standardQuestionnaires.filter(q => getters.hasCompletedSpecificQuestionnaire(q)),
  userRecommendations: (state, getters) => {
    const answersWithRecommendations = state.questionnaires
      .map(qa =>
        qa.slides
          .filter(s => !s.intro)
          .map(q => q.options.filter(o => !!o.recommendation).map(o => ({ ...o, question: q.id })))
          .flat()
      )
      .flat()
    return answersWithRecommendations
  },
  percentScoreOnQuestionnaire: state => questionnaire => {
    const qr = questionnaire
    if (!qr?.answers) {
      return 0
    }
    const answeredQuestions = qr.slides
      .map(q => {
        return !!qr.answers[q.id]
          ? {
              weight: q.options.filter(o => !o.intro).find(o => o.id.toString() === qr.answers[q.id]).weight,
              maxWeight: Math.max(...q.options.map(o => o.weight)),
            }
          : null
      })
      .filter(Boolean)

    const userWeights = answeredQuestions.map(q => q.weight).reduce((a, b) => a + b, 0)
    const maxWeights = answeredQuestions.map(q => q.maxWeight).reduce((a, b) => a + b, 0)

    return parseFloat((userWeights / maxWeights) * 85, 0)
  },
  capacityFromPercent: state => percent =>
    state.capacities.find(c => c.maximum >= percent) || state.capacities[state.capacities.length - 1],
  capacityOfQuestionnaire: (_, getters) => questionnaire =>
    getters.capacityFromPercent(getters.percentScoreOnQuestionnaire(questionnaire)),
  filterFromId: state => id => state.filters.find(f => f.id === id),
  selectedFiltersInQuestionnaire: (state, getters) => questionnaireId => {
    const questionnaire = state.questionnaires.find(q => q.id === questionnaireId)
    if (!questionnaire) return null
    const slidesWithAnswers = questionnaire.slides
      .filter(s => !s.intro)
      .filter(s => Object.keys(questionnaire.answers || {}).includes(s.id.toString()))
    const selectedOptions = slidesWithAnswers
      .map(s => s.options.find(o => o.id === parseInt(questionnaire.answers[s.id], 10)))
      .filter(s => !!s.filter)
      .map(s => getters.filterFromId(s.filter))
    return selectedOptions
  },
  filteredSlidesInQuestionnaire: (_, getters) => questionnaire => {
    const selectedFilters = getters.selectedFiltersInQuestionnaire(questionnaire?.id)
    if (!selectedFilters) return null
    const slidesToExclude = selectedFilters.map(f => f.exclude).flat()
    const slidesToInclude = selectedFilters.map(f => f.include).flat()
    return questionnaire.slides.filter(
      s => !slidesToExclude.includes(s.id) && (!s.disabled || slidesToInclude.includes(s.id))
    )
  },
  definitionFromId: state => id => state.definitions.find(d => d.id === id),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

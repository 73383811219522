var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"donut-container"},[_c('div',{staticClass:"donut",style:({ width: ((_vm.size + _vm.lineExtension * 2) + "px"), height: ((_vm.size / 2 + _vm.lineExtension) + "px") })},[_c('div',{staticClass:"top-half",style:({ width: (_vm.size + "px"), height: ((_vm.size / 2) + "px"), top: (_vm.lineExtension + "px"), left: (_vm.lineExtension + "px") })},[_c('div',{staticClass:"color-circle",style:({
          width: (_vm.size + "px"),
          height: (_vm.size + "px"),
          background: _vm.background,
        })},[_c('div',{staticClass:"negative-color",style:({ width: (_vm.size + "px"), height: ((_vm.size / 2) + "px"), transform: ("rotate(" + _vm.negativeRotation + "deg)") })}),_c('div',{staticClass:"middle-circle",style:({
            width: ((_vm.size - _vm.thickness * 2) + "px"),
            height: ((_vm.size - _vm.thickness * 2) + "px"),
            left: (_vm.thickness + "px"),
            top: (_vm.thickness + "px"),
          })})])]),(_vm.displayedPercent)?_c('div',{staticClass:"line-container",style:({
        width: ((_vm.size + _vm.lineExtension * 2) + "px"),
        height: ((_vm.size / 2 + _vm.lineExtension) + "px"),
        transform: ("rotate(" + _vm.negativeRotation + "deg)"),
      })},[_c('div',{staticClass:"line"})]):_vm._e()]),_c('capacity',{staticClass:"mt-2",attrs:{"percent":_vm.percent,"hide-info":_vm.hideInfo},on:{"info":function($event){return _vm.$emit('info')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="d-flex align-items-center mb-5">
    <icon name="chevron-small-left" size="24px" />
    <b-link :to="to" @click="!to ? $router.back() : null"><slot /></b-link>
  </div>
</template>

<script>
export default {
  name: 'back-button',
  props: {
    to: {
      type: [String, Object],
      default: undefined,
    },
  },
}
</script>

// import '@babel/polyfill'
import 'mutationobserver-shim'
import './plugins/axios'
import './plugins/bootstrap-vue'
import './init'
// SASS
import '@/styles/index.scss'
// IMAGES
import '@/assets/img/apple-touch-icon.png'
// import '@/assets/img/favicon.ico'
// FONTS
import '@/assets/icomoon/style.css'

import Vidle from 'v-idle'
import Vue from 'vue'
import VueGtag from 'vue-gtag'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueGtag, { config: { id: 'G-26BC6SZXHT' } }, router)
Vue.use(Vidle)

new Vue({
  router,
  store,
  async created() {
    await this.$store.dispatch('ui/LOAD_GLOBALS')
    await this.$store.dispatch('pages/LOAD_PAGES')
    await this.$store.dispatch('account/LOAD_CURRENT_USER', this.$route.params.userId)
    await this.$store.dispatch('toolkits/LOAD_TOOLKITS')
  },
  render: h => h(App),
}).$mount('#app')

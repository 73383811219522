<template>
  <div>
    <div class="top">
      <div></div>
      <b-link v-if="hasClose" @click="$router.back()">
        <icon name="clear" size="32px" color="var(--dark)" />
      </b-link>
    </div>
    <div class="mx-0 mx-md-4">
      <b-container class="content-container" fluid="xl">
        <b-row class="align-items-end">
          <b-col :cols="!!data.mainImage ? 8 : 12">
            <h1>{{ data.heading || data.title }}</h1>
            <div class="lead-in" v-html="data.leadIn"></div>
          </b-col>
          <b-col v-if="!!data.mainImage" cols="4">
            <b-img :src="data.mainImage" fluid></b-img>
          </b-col>
        </b-row>
      </b-container>
      <div class="my-5" v-html="data.description"></div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <b-link v-if="hasBack" class="back lead" @click="$emit('back')">Back</b-link>
          <b-link v-else-if="hasStarted" class="back lead" @click="onClearResults">Clear past results</b-link>
        </div>
        <cta-button variant="primary" size="lg" type="button" @click="$emit('next')">{{ buttonLabel }}</cta-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'intro',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    questionnaire: {
      type: Object,
      default: () => {},
    },
    buttonLabel: {
      type: String,
      default: 'Begin',
    },
    hasClose: {
      type: Boolean,
      default: true,
    },
    hasBack: {
      type: Boolean,
      default: true,
    },
    hasStarted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClearResults() {
      this.$store.dispatch('riskmanagement/RESET_ANSWERS_IN_QUESTIONNAIRE', this.questionnaire.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.top {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.content-container {
  padding: 0;
}

.lead-in {
  font-size: $font-size-lg;
}
</style>

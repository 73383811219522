<template>
  <div v-if="pageContent" :class="{ ['logged-in-container']: loggedIn }">
    <b-container v-if="loggedIn && user.organization" class="my-5" fluid="xl">
      <b-row>
        <b-col>
          <h1>Welcome<br />{{ user.organization }}</h1>
        </b-col>
      </b-row>
    </b-container>
    <banner v-else :content="pageContent.banner" />
    <b-container class="my-5" fluid="xl">
      <b-row>
        <b-col>
          <b-card-group deck>
            <toolkit-card
              v-for="toolkit in toolkitsToShow"
              :key="toolkit.slug"
              icon="star-o"
              :title="toolkit.title"
              :sub-title="toolkit.tagline"
              :active="toolkit.active"
              :date="toolkit.lastOpened"
              :to="`/${toolkit.uri}/overview`"
            />
          </b-card-group>
        </b-col>
      </b-row>
      <b-row v-if="!loggedIn">
        <b-col>
          <h4 class="my-5 text-center">
            Get started, login and create an account. <b-link :to="{ name: 'register' }">Sign up</b-link>
          </h4>
        </b-col>
      </b-row>
    </b-container>
    <clipart v-if="loggedIn" :data="pageContent.clipart" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'toolkits',
  title: 'Toolkits',
  computed: {
    ...mapGetters({
      pageFromSlug: 'pages/pageFromSlug',
      toolkits: 'toolkits/toolkits',
      loggedIn: 'account/loggedIn',
      user: 'account/user',
    }),
    pageContent() {
      return this.pageFromSlug(this.$route.name)
    },
    toolkitsToShow() {
      return this.loggedIn ? this.toolkits?.filter(t => t.active) : this.toolkits
    },
  },
  created() {
    this.$store.commit('toolkits/RESET_STATE')
  },
}
</script>

<style lang="scss" scoped>
.logged-in-container {
  margin-bottom: 390px;
}

.card-deck .card {
  flex: 1 0 30%;

  @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    flex: 0 0 30%;
  }
}
</style>

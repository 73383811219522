// import $store from '../store/index'
import * as API from './Api'

/*
USAGE:
import AccountService from '../js/services/AccountService'
...
methods: {
    async myFunction () {
      const response = await AccountService.registerUser(formData)
      if (response.data.length) {
        // do something
      }
    }
}
*/
export default {
  // SETTERS
  // registerUser(postData) {
  //   return API.post('/account/register', postData)
  // },
  // addInvitation(postData) {
  //   return API.post('/', { ...postData, ...API.saveEntryConfig(9) })
  // },
  // editProject(postData, projectId) {
  //   return API.post('/', { ...postData, ...API.editEntryConfig(projectId) })
  // },
  // editUser(postData, userId) {
  //   return API.post('/', { ...postData, ...API.editUserConfig(userId) })
  // },
  // editPassword(postData, userId) {
  //   return API.post('/', { ...postData, ...API.editUserConfig(userId) })
  // },

  // GETTERS
  getPages() {
    return API.get('/api/get-pages')
  },
  getToolkits() {
    return API.get('/api/get-toolkits')
  },
  // getTeamsInDept (deptId) {
  //   return API.get('/api/teams-in-dept/' + deptId)
  // },
}

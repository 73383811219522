<template lang="html">
  <div class="logo">
    <img
      :src="simple ? privateStateTag.imgSrc : privateState.imgSrc"
      :style="`height: ${height}px;`"
      :class="variant"
      alt="Logo"
    />
  </div>
</template>

<script lang="js">
import img from '@/assets/img/IntegralOrg.svg';
import imgTag from '@/assets/img/IntegralOrgTagColor.svg';
// import s from "../store"

export const variants = ['color', 'dark', 'light'];

export default {
  name: 'logo',
  props: {
    height: {
      type: Number,
      default: 70,
    },
    variant: {
      type: String,
      default: 'color',
      validator: (val) => variants.includes(val),
    },
    simple: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      privateState: {
        imgSrc: img,
      },
      privateStateTag: {
        imgSrc: imgTag,
      },
      // sharedState: s.state
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  &.dark {
    filter: brightness(0);
    opacity: 0.7;
  }

  &.light {
    filter: brightness(0) invert(1);
  }
}
</style>

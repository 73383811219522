<template>
  <h3><slot /></h3>
</template>

<script>
export default {
  name: 'bold-title',
}
</script>

<style lang="scss" scoped>
h3 {
  background-color: $integral-blue;
  border-radius: 30px;
  color: $white;
  padding: 5px 30px;
}
</style>

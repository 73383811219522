<template>
  <div class="recommendations">
    <div>
      <h4>{{ title }}</h4>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'recommendations',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.recommendations {
  background-color: lighten($primary, 40%);
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  padding: 50px;

  p:last-child {
    margin-bottom: 0;
  }

  ul {
    list-style: inside;
    margin-bottom: 0;
    padding-left: 0;

    li {
      margin-bottom: 5px;
    }
  }
}
</style>

<template>
  <div v-if="percent" class="capacity" :style="`color: var(--${color})`">
    {{ capacity.title }}
    <span v-if="!hideInfo" @click="$emit('info')">
      <icon name="information-solid" size="16px" color="var(--gray-500)" class="info align-top" />
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'capacity',
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    hideInfo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      capacityFromPercent: 'riskmanagement/capacityFromPercent',
    }),
    color() {
      return this.percent < 36 ? 'red-light' : 'blue'
    },
    capacity() {
      return this.capacityFromPercent(this.percent)
    },
  },
}
</script>

<style lang="scss" scoped>
.capacity {
  font-size: $font-size-base;
  font-weight: bold;
  position: relative;
  text-align: center;
  width: 100%;
}

.info {
  cursor: pointer;
  position: relative;

  &:hover {
    color: var(--gray-700) !important;
  }
}
</style>

<template>
  <b-form-group :id="`${id}-group`" :label="label" :label-for="id">
    <b-form-select
      :id="id"
      v-model="formData[id][0]"
      :options="options"
      class="field"
      size="lg"
      :required="required"
    ></b-form-select>
    <b-form-text v-if="!!$slots.default"><slot /></b-form-text>
  </b-form-group>
</template>

<script>
export default {
  name: 'select-field',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    formData: {
      type: Object,
      default: () => {},
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.field {
  border: none;
  border-bottom: 2px solid $gray-300;
  border-radius: 0;
  outline: none;

  &:focus {
    border-bottom-color: $blue;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  }
}

::v-deep .form-text a {
  color: $text-muted;
  text-decoration: none;

  &:hover {
    color: $blue;
  }
}
</style>

<template>
  <b-card class="mb-5" :bg-variant="!active ? 'light' : ''">
    <div class="card-content">
      <h3 class="mb-0">{{ title }}</h3>
      <p class="my-4">{{ subTitle }}</p>
      <small v-if="active && date" class="mb-2">
        Last opened <span>{{ date }}</span>
      </small>
      <cta-button v-if="active" variant="primary" size="md" :to="to">Learn more</cta-button>
      <cta-button v-else variant="outline-secondary" size="md" disabled>Coming soon</cta-button>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'toolkit-card',
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.card-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 15px 0;
  text-align: center;
}

.icon-holder {
  align-items: center;
  background-color: $gray-300;
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
}

.soon {
  text-transform: uppercase;
}

small {
  span {
    color: $gray-600;
  }
}
</style>

<template>
  <b-button
    pill
    :variant="variant"
    type="type"
    :size="size"
    :href="href"
    :to="to"
    :disabled="disabled"
    @click="$emit('click')"
    ><slot
  /></b-button>
</template>

<script>
export const variants = ['primary', 'outline-primary', 'secondary', 'outline-secondary', 'success']
export const sizes = ['lg', 'md', 'sm']

export default {
  name: 'cta-button',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: val => variants.includes(val),
    },
    size: {
      type: String,
      default: 'lg',
      validator: val => sizes.includes(val),
    },
    type: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

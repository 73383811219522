<template>
  <div>
    <b-form-radio
      v-model="modelObj"
      size="lg"
      :value="value"
      :class="{ active: modelObj === value }"
      @input="$emit('input', arguments[0])"
    >
      <slot />
    </b-form-radio>
  </div>
</template>

<script>
export default {
  name: 'radio',
  props: {
    model: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelObj: this.model,
    }
  },
  watch: {
    model(newValue, oldValue) {
      this.modelObj = newValue
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-radio.b-custom-control-lg {
  border: 2px solid transparent;
  font-size: $font-size-base * 1.15;
  margin: 3px 0;
  padding: 3px 30px 3px 40px;

  &.active {
    background-color: rgba($blue, 0.3);
    border: 2px solid $blue;
    border-radius: 20px;
    display: inline-block;
    margin: 0;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

import Toolkits from '@/components/pages/Toolkits.vue'
import store from '@/store'

Vue.use(VueRouter)

const authenticationRequired = (to, from, next) => {
  if (!store.getters['account/loggedIn']) next({ name: 'sign-in' })
  else next()
}

const cantBeAuthenticated = (to, from, next) => {
  if (store.getters['account/loggedIn']) next({ name: 'home' })
  else next()
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Toolkits,
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/components/pages/Home.vue'),
  },
  {
    path: '/toolkit',
    component: () => import('@/components/pages/Toolkit.vue'),
    children: [
      {
        path: 'definition/:definitionId',
        name: 'definition',
        component: () => import('@/components/pages/Definition.vue'),
      },
      // RISK MANAGEMENT
      {
        path: 'risk-management',
        component: () => import('@/components/pages/RiskManagement.vue'),
        children: [
          {
            path: 'overview',
            name: 'risk-management-toolkit',
            component: () => import('@/components/pages/risk-management/RiskManagementToolkit.vue'),
          },
          {
            path: 'dashboard',
            name: 'rm-dashboard',
            component: () => import('@/components/pages/risk-management/Dashboard.vue'),
            beforeEnter: authenticationRequired,
          },
          {
            path: 'dashboard-:userId',
            name: 'rm-dashboard-results',
            component: () => import('@/components/pages/risk-management/Dashboard.vue'),
          },
          {
            path: 'review/r-:questionnaireId-:userId',
            name: 'rm-result-public',
            component: () => import('@/components/pages/risk-management/Review.vue'),
          },
          {
            path: 'review/:questionnaireId',
            name: 'rm-result',
            component: () => import('@/components/pages/risk-management/Review.vue'),
            // beforeEnter: authenticationRequired,
          },
          {
            path: 'resources',
            name: 'rm-resources',
            component: () => import('@/components/pages/risk-management/Resources.vue'),
            beforeEnter: authenticationRequired,
          },
          {
            path: 'questionnaire/:questionnaire',
            name: 'rm-assessment',
            component: () => import('@/components/pages/risk-management/Assessment.vue'),
            beforeEnter: authenticationRequired,
          },
          {
            path: 'definition/:definitionId',
            name: 'risk-management-definition',
            component: () => import('@/components/pages/Definition.vue'),
          },
        ],
      },
      // SOCIAL ENTERPRISE
      {
        path: 'social-enterprise',
        component: () => import('@/components/pages/SocialEnterprise.vue'),
        children: [
          {
            path: 'overview',
            name: 'social-enterprise-toolkit',
            component: () => import('@/components/pages/social-enterprise/SocialEnterpriseToolkit.vue'),
          },
          {
            path: 'questionnaire',
            name: 'se-questionnaire',
            component: () => import('@/components/pages/social-enterprise/Questionnaire.vue'),
            beforeEnter: authenticationRequired,
          },
          {
            path: 'review/:sessionId',
            name: 'se-review',
            component: () => import('@/components/pages/social-enterprise/Review.vue'),
            beforeEnter: authenticationRequired,
          },
          {
            path: 'result/r-:resultId',
            name: 'se-result',
            component: () => import('@/components/pages/social-enterprise/ResultPage.vue'),
          },
          {
            path: 'result/:sessionId',
            name: 'se-result',
            component: () => import('@/components/pages/social-enterprise/ResultPage.vue'),
            beforeEnter: authenticationRequired,
          },
          {
            path: 'case-study/:caseStudyId',
            name: 'se-case-study',
            component: () => import('@/components/pages/social-enterprise/CaseStudy.vue'),
          },
          {
            path: 'definition/:definitionId',
            name: 'social-enterprise-definition',
            component: () => import('@/components/pages/Definition.vue'),
          },
          {
            path: '*',
            name: 'info',
            component: () => import('@/components/pages/social-enterprise/Info.vue'),
          },
        ],
      },
      {
        path: '*',
        component: () => import('@/components/pages/GenericToolkit.vue'),
      },
    ],
  },

  // AUTHENTICATION
  {
    path: '/auth',
    component: () => import('@/components/pages/Auth.vue'),
    children: [
      {
        path: 'sign-in',
        name: 'sign-in',
        component: () => import('@/components/pages/authentication/SignIn.vue'),
        beforeEnter: cantBeAuthenticated,
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/components/pages/authentication/Register.vue'),
        beforeEnter: cantBeAuthenticated,
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('@/components/pages/authentication/ForgotPassword.vue'),
        beforeEnter: cantBeAuthenticated,
      },
      {
        // http://integralorg.nitro:8080/setpassword?code=nEA5RoRfPGD00Q7dbDfDN0swon1K1VJ1&id=26cd90dd-9917-4354-a058-bfdb1bea48aa
        path: 'setpassword',
        name: 'setpassword',
        component: () => import('@/components/pages/authentication/ResetPassword.vue'),
        beforeEnter: cantBeAuthenticated,
      },
      {
        path: 'change-password/',
        name: 'change-password',
        component: () => import('@/components/pages/authentication/ChangePassword.vue'),
        beforeEnter: authenticationRequired,
      },
      {
        path: 'edit-details',
        name: 'edit-details',
        component: () => import('@/components/pages/authentication/EditDetails.vue'),
        beforeEnter: authenticationRequired,
      },
      {
        path: 'organization-details',
        name: 'organization-details',
        component: () => import('@/components/pages/authentication/EditDetails.vue'),
        beforeEnter: authenticationRequired,
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

export default router

<template>
  <b-alert v-if="show" variant="success" show class="d-flex align-items-center my-4"
    ><icon name="check" size="25px" color="var(--green)" class="mr-3" /><strong class="mr-2">Success:</strong>
    {{ message }}</b-alert
  >
</template>

<script>
export default {
  name: 'success-alert',
  props: {
    message: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

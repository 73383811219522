<template>
  <div class="container">
    <b-img :src="image" alt="Engagement" fluid />
    <div class="content" :style="{ width }">
      <div class="description" :style="{ textColor: color }">{{ label }}</div>
      <small>Engagement</small>
      <div class="retake">
        <strong class="d-block mb-2">
          <big>How engaged is your organization with Risk Management?</big>
        </strong>
        <b-link v-if="loggedIn && to" class="text-muted" :to="to">Retake general assessment</b-link>
        <b-link
          v-if="!loggedIn"
          class="text-muted"
          :to="{ name: 'rm-result', params: { questionnaireId: assessmentQuestionnaire.id } }"
          >Review assessment</b-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import chart1 from '@/assets/img/risk-management/RM-Chart-01.png'
import chart2 from '@/assets/img/risk-management/RM-Chart-02.png'
import chart3 from '@/assets/img/risk-management/RM-Chart-03.png'
import chart4 from '@/assets/img/risk-management/RM-Chart-04.png'

export default {
  name: 'engagement-chart',
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    to: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      loggedIn: 'account/loggedIn',
      assessmentQuestionnaire: 'riskmanagement/assessmentQuestionnaire',
    }),
    image() {
      if (this.percent > 75) return chart4
      if (this.percent > 50) return chart3
      if (this.percent > 25) return chart2
      return chart1
    },
    width() {
      if (this.percent > 75) return '72%'
      if (this.percent > 50) return '85%'
      if (this.percent > 25) return '101%'
      return '108%'
    },
    color() {
      if (this.percent > 75) return 'var(--integral-blue)'
      if (this.percent > 50) return 'var(--purple)'
      if (this.percent > 25) return 'var(--orange)'
      return 'var(--red)'
    },
    label() {
      if (this.percent > 75) return 'Very high'
      if (this.percent > 50) return 'High'
      if (this.percent > 25) return 'Moderate'
      return 'Low'
    },
  },
}
</script>

<style lang="scss" scoped>
$radius: 80px;
$diameter: 250px;

.container {
  height: 370px;
  position: relative;
  width: 350px;

  .content {
    position: absolute;
    text-align: center;
    top: 170px;
    width: 100%;

    .description {
      color: $integral-blue;
      font-size: 30px;
      font-weight: bold;
    }

    small {
      color: $gray-700;
      display: block;
      font-size: 16px;
    }

    .retake {
      margin: auto;
      margin-top: 25px;
      width: 85%;
    }
  }
}
</style>

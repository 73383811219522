import PagesService from '@/services/Pages.service'

const state = {
  pages: [],
}

// Vue.set(state, 'address', payload)
const mutations = {
  SET_PAGES: (state, payload) => {
    state.pages = payload
  },
}

const actions = {
  LOAD_PAGES: async ({ commit }) => {
    const pagesResponse = await PagesService.getPages()
    if (pagesResponse.ok) {
      commit('SET_PAGES', pagesResponse.data)
    }
  },
}

const getters = {
  pages: state => state.pages,
  pageFromSlug: state => slug => state.pages.find(p => p.slug === slug),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import dayjs from 'dayjs'
import Vue from 'vue'

import ToolkitsService from '@/services/Toolkits.service'

const state = {
  questions: [],
  sessions: [],
  answers: [],
  results: [],
  resources: [],
  definitions: [],
  caseStudies: [],
  session: null,
  currentQuestionId: null,
  currentResult: null,
  questionsAsked: [],
}

const mutations = {
  SET_QUESTIONS: (state, payload) => {
    state.questions = payload
  },
  SET_SESSIONS: (state, payload) => {
    state.sessions = payload || []
  },
  SET_ANSWERS: (state, payload) => {
    state.answers = payload || []
  },
  SET_RESULTS: (state, payload) => {
    state.results = payload || []
  },
  SET_CASE_STUDIES: (state, payload) => {
    state.caseStudies = payload || []
  },
  SET_RESOURCES: (state, payload) => {
    state.resources = payload || []
  },
  SET_DEFINITIONS: (state, payload) => {
    state.definitions = payload || []
  },
  SET_SESSION: (state, id) => {
    state.session = id
  },
  SET_CURRENT_QUESTION_ID: (state, id) => {
    state.currentQuestionId = id
  },
  SET_CURRENT_RESULT: (state, id) => {
    state.currentResult = id
  },
  SET_ANSWER: (state, payload) => {
    state.questionsAsked.push(payload.question)
    state.questionsAsked = [...new Set(state.questionsAsked)] // remove dups
    state.answers = state.answers.filter(a => a.session !== payload.session && a.question !== payload.question)
    state.answers.push(payload)
  },
  SET_QUESTIONS_ASKED: (state, payload) => {
    state.questionsAsked = payload
  },
  REMOVE_ANSWER: (state, id) => {
    state.answers = state.answers.filter(a => a.id !== id)
  },
  REMOVE_SESSION: (state, id) => {
    state.sessions = state.sessions.filter(s => s.id !== id)
  },
  EDIT_SESSION_NAME: (state, { id, title }) => {
    const sessionIndex = state.sessions.findIndex(s => s.id === id)
    Vue.set(state.sessions[sessionIndex], 'title', title)
  },
  REMOVE_QUESTION_ASKED: (state, id) => {
    state.questionsAsked = state.questionsAsked.filter(qId => qId !== id)
  },
  CLEAR_SESSION: state => {
    state.currentQuestionId = state.questions[0].id
    state.currentResult = null
    state.questionsAsked = []
    state.session = null
  },
  RESET_STATE: state => {
    state.currentQuestionId = state.questions[0]?.id || null
    state.sessions = []
    state.answers = []
    state.session = null
    state.currentQuestionId = null
    state.currentResult = null
    state.questionsAsked = []
  },
}

const actions = {
  LOAD_QUESTIONS: async ({ commit }) => {
    const response = await ToolkitsService.getSocialEnterpriseToolkit()
    if (response.ok) {
      if (response.error) {
        this.$store.dispatch('account/RESET_STATE', null, { root: true })
        window.location.href = '/logout'
      } else {
        commit('SET_CURRENT_QUESTION_ID', response.data.questions[0].id)
        commit('SET_QUESTIONS', response.data.questions)
        commit('SET_RESULTS', response.data.results)
        commit('SET_CASE_STUDIES', response.data.caseStudies)
        commit('SET_RESOURCES', response.data.resources)
        commit('SET_DEFINITIONS', response.data.definitions)
      }
    }
  },
  LOAD_ANSWERS: async ({ commit }) => {
    const response = await ToolkitsService.getSocialEnterpriseToolkitAnswers()
    if (response.ok) {
      commit('SET_SESSIONS', response.data.sessions)
      commit('SET_ANSWERS', response.data.answers)
    }
  },
  RESET_ANSWERS: async ({ commit }) => {
    await commit('RESET_ANSWERS')
  },
  SET_ANSWER: async ({ commit, state, rootGetters }, payload) => {
    // create new session if it doesn't exist yet
    if (!state.session) {
      const userName = rootGetters['account/name']
      const response = await ToolkitsService.addAnswerSessionEntry({
        title: dayjs().format('MMM D, YYYY, h:mm A'),
      })
      commit('SET_SESSION', response.data.id)
    }

    // update api with new answer
    const answerPayload = {
      title: payload.title,
      fields: {
        question: [payload.question],
        answer: payload.answer,
        answerLabel: payload.answerLabel,
        session: [state.session],
      },
    }
    const answerResponse = await ToolkitsService.addAnswerEntry(answerPayload)

    // update state with new answer
    commit('SET_ANSWER', {
      id: answerResponse.data.id,
      answer: payload.answer,
      answerLabel: payload.answerLabel,
      question: payload.question,
      session: state.session,
    })
  },
  SET_SESSION_COMPLETE: async ({ state, dispatch }, resultId) => {
    await ToolkitsService.makeAnswerSessionComplete(state.session, resultId)
    await dispatch('LOAD_ANSWERS')
  },
  REMOVE_CURRENT_ANSWER: async ({ commit, state, getters }) => {
    const currentAnswer = getters.currentAnswers.find(a => a.question === state.currentQuestionId)
    if (!!currentAnswer) {
      await ToolkitsService.removeEntry(currentAnswer.id)
      await commit('REMOVE_ANSWER', currentAnswer.id)
    }
    await commit('REMOVE_QUESTION_ASKED', state.currentQuestionId)
    await commit(
      'SET_CURRENT_QUESTION_ID',
      state.questionsAsked[state.questionsAsked.length - 1] || state.questions[0].id
    )
  },
  DELETE_SESSION: async ({ state, commit }, sessionId) => {
    const allAnswersInSession = state.answers.filter(a => a.session === sessionId)
    for await (const answer of allAnswersInSession) {
      await ToolkitsService.removeEntry(answer.id)
      await commit('REMOVE_ANSWER', answer.id)
    }
    await ToolkitsService.removeEntry(sessionId)
    await commit('REMOVE_SESSION', sessionId)
  },
  EDIT_SESSION_NAME: async ({ state, commit }, { sessionId, title }) => {
    await ToolkitsService.editEntryTitle(sessionId, title)
    await commit('EDIT_SESSION_NAME', { id: sessionId, title })
  },
  CONTINUE_SESSION: async ({ state, commit }, sessionId) => {
    commit('SET_SESSION', sessionId)
    const allAnswersInSession = state.answers.filter(a => a.session === sessionId)
    if (allAnswersInSession.length) {
      const lastAnswer = allAnswersInSession[allAnswersInSession.length - 1]
      const lastQuestionAnswered = state.questions.find(q => q.id === lastAnswer.question)

      const currentQuestionId = lastQuestionAnswered.options.find(
        o => o.id.toString() === lastAnswer.answer
      )?.followUpQuestion
      commit('SET_CURRENT_QUESTION_ID', currentQuestionId)
      commit(
        'SET_QUESTIONS_ASKED',
        allAnswersInSession.map(a => a.question)
      )
    }
  },
}

const getters = {
  questions: state => state.questions,
  sessions: state => state.sessions,
  answers: state => state.answers,
  caseStudies: state => state.caseStudies,
  results: state => state.results,
  resources: state => state.resources,
  definitions: state => state.definitions,
  questionnaireLink: (_, getters, rootState, rootGetters) =>
    '/' + rootGetters['toolkits/currentToolkit'].uri + '/questionnaire',
  reviewLink: (_, getters, rootState, rootGetters) => sessionId =>
    '/' + rootGetters['toolkits/currentToolkit'].uri + '/review/' + sessionId,
  resultLink: (_, getters, rootState, rootGetters) => sessionId =>
    '/' + rootGetters['toolkits/currentToolkit'].uri + '/result/' + sessionId,
  caseStudyLink: (_, getters, rootState, rootGetters) => caseStudyId =>
    '/' + rootGetters['toolkits/currentToolkit'].uri + '/case-study/' + caseStudyId,
  definitionLink: (_, getters, rootState, rootGetters) => definitionId =>
    '/' + rootGetters['toolkits/currentToolkit'].uri + '/definition/' + definitionId,
  currentQuestionId: state => state.currentQuestionId,
  currentQuestionData: state => state.questions.find(q => q.id === state.currentQuestionId) || { results: true },
  currentAnswers: state => state.answers.filter(a => a.session === state.session),
  questionsAsked: state => state.questionsAsked,
  answersInSession: state => sessionId => state.answers.filter(a => a.session?.toString() === sessionId),
  session: state => sessionId => state.sessions.find(s => s.id.toString() === sessionId),
  sessionResult: (state, getters) => sessionId => state.results.find(r => r.id === getters.session(sessionId).result),
  resultFromId: state => resultId => state.results.find(r => r.id === resultId),
  result: state => state.results.find(r => r.id === state.currentResult),
  caseStudy: state => csId => state.caseStudies.find(c => c.id.toString() === csId.toString()),
  definitionFromId: state => id => state.definitions.find(d => d.id === id),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import UIService from '@/services/UI.service'

const state = {
  address: '',
  socialLinks: '',
  headerMenu: '',
  footerMenu: '',
  now: '',
}

// Vue.set(state, 'address', payload)
const mutations = {
  SET_ADDRESS: (state, payload) => {
    state.address = payload
  },
  SET_SOCIAL_LINKS: (state, payload) => {
    state.socialLinks = payload
  },
  SET_HEADER_MENU: (state, payload) => {
    state.headerMenu = payload
  },
  SET_FOOTER_MENU: (state, payload) => {
    state.footerMenu = payload
  },
  SET_NOW: (state, payload) => {
    state.now = payload
  },
}

const actions = {
  LOAD_GLOBALS: async ({ commit }) => {
    const response = await UIService.getGlobals()
    if (response.ok) {
      commit('SET_ADDRESS', response.data.address)
      commit('SET_SOCIAL_LINKS', response.data.socialLinks)
      commit('SET_HEADER_MENU', response.data.headerMenu)
      commit('SET_FOOTER_MENU', response.data.footerMenu)
      commit('SET_NOW', response.data.now)
      const csrf = { [response.data.csrf.name]: response.data.csrf.value }
      localStorage.setItem('csrf', JSON.stringify(csrf))
    }
  },
}

const getters = {
  address: state => state.address,
  socialLinks: state => state.socialLinks,
  headerMenu: state => state.headerMenu,
  footerMenu: state => state.footerMenu,
  now: state => state.now,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
